// Default variable overrides
$body-bg: #fff; 
$body-color: #171717;
$footer-bg: #1D2843;
$hero-bg: #4D5A62;
$gold-bg: #D2A825;
$gold-color:#D2A825; 
$title-color:#2b2b2b;
$font-family: 'Montserrat';
$fg-white: #ffff;
$odd-bg: #F2F6F7;