
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.boredr-bg {
  background: #fbfbfb;
    padding: 10px;
    border-radius: 10px;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  /* opacity: 0.4; */
}
.ceo-section p{
  line-height: 35px;
}
li.nav-item {
  background: #4d5a62;
  margin: 0 5px;
  text-align: center;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-thumbs .swiper-slide {
  width: 235px !important;
  height: 140px;
  border: 1px solid #afc5fb;
  display: inline-flex;
  margin-bottom: 10px;
}
.swiper-pointer-events {
  touch-action: pan-y;
  /* height: 440px; */
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-thumbs .swiper-wrapper {
  display: inline;

}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.mySwiper2 .swiper-slide{
  height: 100% !important;
}
.caption {
  position: absolute;
    bottom: 0;
    z-index: 99999;
    background: #fff;
    width: 100%;
}
.app-innerSection .thumbnail-img .content-overlay {
  background: rgb(0 0 0 / 35% ) !important;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.mySwiper2 .swiper-slide img {
  object-position: top;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0 !important;
  left: 52% !important;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 48% !important;
  right: 0 !important;
}
.swiper-button-next, .swiper-button-prev{
  top: 88% !important
}
.swiper-container.mySwiperCustom .mySwiper .swiper-wrapper {
  height: 150px !important}
  .swiper-container.mySwiperCustom .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.mySwiper{
    height: 250px !important;
  }

  .swiper-slide.swiper-slide-active img{
    filter: grayscale(0);
    opacity: 1;  
  }

  .mySwiper3 .swiper-slide {
    background: #4D5A62 !important;
  }
